"use client";
import { Fragment } from "react";
import classNames from "classnames";
import { ReactSVG } from "react-svg";
import { Link } from "../Link";
import { Chip } from "@/components/Chip";

import { ButtonProps } from "./types";

import "./styles.scss";

export const Button = ({
  to,
  dataQA,
  dataTestId,
  buttonId,
  label,
  description,
  full,
  secondary,
  small,
  medium,
  large,
  extralarge,
  ghost,
  square,
  icon,
  iconLeft,
  iconTop,
  disabled,
  bold,
  step,
  loading,
  type,
  customClass,
  className,
  active,
  spacing,
  buttonColor,
  block,
  tertiary,
  title,
  onClick,
  onMouseEnter,
  ariaLabel,
  chip,
  noPadding = false,
}: ButtonProps) => {
  const classnames = classNames(`button`, {
    [`${customClass}`]: customClass,
    [`button--${className}`]: className,
    "button--secondary": secondary,
    "button--small": small,
    "button--medium": medium,
    "button--large": large,
    "button--extralarge": extralarge,
    "button--square": square,
    "button--ghost": ghost,
    "button--full": full,
    "button--icon": icon || loading,
    "button--tertiary": tertiary,
    "button--iconLeft": iconLeft || bold || loading || step !== undefined,
    "button--iconTop": iconTop,
    "button--loading": loading,
    "button--disabled": disabled || loading,
    "button--description": description,
    "button--block": block,
    "button--bold": bold || step !== undefined,
    "button--step": step !== undefined,
    "button--active": active,
    "button--withChip": chip,
    "button--no-padding": noPadding,
    [`u-spacing-${spacing}`]: spacing,
  });

  const buttonContent = (
    <Fragment>
      {label && !description && (
        <span
          data-testid={dataTestId || "button-label"}
          className="button__text"
        >
          {loading ? "Loading" : label}
        </span>
      )}
      {label && description ? (
        <div
          data-testid={dataTestId || "button-description"}
          className="button__description"
        >
          <span className="button__description__title">{label}</span>
          <span className="button__description__subtitle">{description}</span>
        </div>
      ) : null}
      {step !== undefined && !icon ? (
        <div
          data-testid={dataTestId || "button-step-icon"}
          className="button--step--icon"
        >
          {step}
        </div>
      ) : null}
      {icon ? (
        loading ? (
          <Fragment>
            <ReactSVG src="/svg/loading-icon.svg" />
          </Fragment>
        ) : (
          <Fragment>{icon}</Fragment>
        )
      ) : null}
      {chip && (
        <Chip
          label={chip}
          color={"warning"}
          type={"filled"}
          className={"button--chip"}
        />
      )}
    </Fragment>
  );

  return to ? (
    <Link
      aria-label={ariaLabel || label}
      data-testid={dataTestId || "button"}
      data-qa={dataQA}
      href={to}
      id={buttonId}
      className={classnames}
      type={type ? type : to ? "button" : "submit"}
      onClick={onClick}
      //    title={title}
      style={{ backgroundColor: buttonColor }}
      /*       nonce={undefined}
      onResize={undefined}
      onResizeCapture={undefined} */
    >
      {buttonContent}
    </Link>
  ) : (
    <button
      aria-label={ariaLabel || label}
      data-testid={dataTestId || "button"}
      data-qa={dataQA}
      id={buttonId}
      className={classnames}
      type={type || "submit"}
      style={{
        backgroundColor: buttonColor,
        display: "flex",
        justifyContent: "center",
      }}
      title={title}
      onClick={onClick}
      onMouseEnter={onMouseEnter}
    >
      {buttonContent}
    </button>
  );
};
