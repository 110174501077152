"use client";

import React, { useState } from "react";
import { AxiosResponse } from "axios";

import { getAuthCookie } from "@/services/CookiesService";

import { LayoutContext } from "@/contexts/LayoutContext";

export const useApi = <T>(apiFunc: any, disableLoading?: boolean) => {
  const layoutContext = React.useContext(LayoutContext);
  const [data, setData] = useState<AxiosResponse | null>(null);
  const [error, setError] = useState<AxiosResponse | undefined>(undefined);
  const [loading, setLoading] = useState<boolean>(null);

  const request = async (data?: any): Promise<AxiosResponse<T, any>> => {
    !disableLoading && setLoading(true);
    try {
      const result: AxiosResponse = await apiFunc({
        authorization: getAuthCookie(),
        ...data,
      });

      setData(result);
      !disableLoading && setLoading(false);
      return result;
    } catch (err) {
      setError(err.response || "Error in request");
      !disableLoading && setLoading(false);
    }
  };

  React.useEffect(() => {
    if (!disableLoading) {
      if (loading !== null) layoutContext?.setIsLoading(loading);
    }
  }, [loading]);

  return {
    data,
    error,
    loading,
    request,
  };
};
