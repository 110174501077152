import React from "react";
import classNames from "classnames";
import { ChipProps } from "./types";
import "./styles.scss";

export function Chip({ label, type, color, className, size, width, mobile, tablet }: ChipProps) {
  const classnames = classNames(`Chip`, {
    [`Chip--${type}`]: type,
    "Chip--primary": color === "primary",
    "Chip--success": color === "success",
    "Chip--warning": color === "warning",
    "Chip--danger": color === "danger",
    "Chip--inactive": color === "inactive",
    "Chip--medium": size === "medium",
    "Chip--fit-content": width === "fit-content",
    "Chip--tablet": tablet === true,
    "Chip--mobile": mobile === true,
    [className]: className,
  });
  return (
    <div className={classnames} data-testid="Chip">
      <span className="text">{label}</span>
    </div>
  );
}
