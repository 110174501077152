"use client";

import classNames from "classnames";

import { SkeletonProps } from "./types";

export const Skeleton = ({
  dataQA,
  className,
  height,
  width,
  backgroundColor,
  children,
  style,
}: SkeletonProps) => {
  const classnames = classNames(`u-skeleton`, {
    [`${className}`]: className,
  });

  return (
    <span
      data-testid="skeleton"
      data-qa={dataQA}
      className={classnames}
      style={{ width, height, backgroundColor, ...style }}
    >
      {children}
    </span>
  );
};
