import LinkNext from "next/link";
import classNames from "classnames";

import { LinkProps } from "./types";

import "./styles.scss";

export const Link = ({
  children,
  href,
  className,
  type,
  externalLink,
  dataQA,
  nofollow,
  ...rest
}: LinkProps) => {
  const classnames = classNames("", {
    link: type !== "button",
    [`button button-link`]: type === "button",
    [`${className}`]: className,
  });

  let rel = undefined;
  if (externalLink) rel = "noreferrer";
  else if (nofollow) rel = "nofollow";

  return (
    <LinkNext
      href={href}
      className={classnames}
      target={externalLink && "_blank"}
      rel={rel}
      passHref={externalLink}
      data-qa={dataQA}
      {...rest}
      prefetch={false}
    >
      {children}
    </LinkNext>
  );
};
